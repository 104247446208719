<template>
  <span class="badge" :style="{ background: `rgba(255, 255, 255, ${props.opacity})` }">{{ props.count }}</span>
</template>

<style scoped>
.badge {
  position: relative;
  cursor: default;
  left: 0;
  top: 0;
  width: 24px;
  height: 18px;
  padding: 0;
  border: none;
  color: black;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  line-height: 18px;
}
</style>

<script setup lang="ts">
const props = defineProps({
  count: { type: Number, required: true },
  opacity: { type: Number, default: 0.5 },
})
</script>
