import { pagesFromGlobRecord, componentsFromGlobRecord } from '@100-m/hauru/src/utils/platformBuilder'
import layout from './Layout.vue'

const pages = pagesFromGlobRecord(import.meta.globEager('./pages/**/*.md'), './pages')
pages[':catchall(.*)'] = import.meta.globEager('./Layout.vue')['./Layout.vue']
const components = {
  ...componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  ...componentsFromGlobRecord(import.meta.globEager('./pages/**/*.story.vue')),
}

export default {
  name: 'design',
  routePrefix: '/design',
  pages,
  components,
  layout,
}
