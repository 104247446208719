export default {
  logo: 'icon.png',
  nav: 'icon.png',
  icon: 'icon.png',
  navSmall: 'nav_small.png',
  auth: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    project: import.meta.env.VITE_PROJECT,
    urls: ['https://fireauth.100m.io/'],
  },
  keycloakAuth: {
    url: import.meta.env.VITE_KEYCLOAK_URL,
    realm: import.meta.env.VITE_KEYCLOAK_REALM,
    clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
    clientSecret: import.meta.env.VITE_KEYCLOAK_CLIENT_SECRET,
  },
  commandr: import.meta.env.VITE_COMMANDR,
  exporter: import.meta.env.VITE_EXPORTER,
  project: import.meta.env.VITE_PROJECT,
  db: import.meta.env.VITE_DB || 'firebase',
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    name: import.meta.env.VITE_FIREBASE_NAME,
    project: import.meta.env.VITE_FIREBASE_PROJECT,
    root: import.meta.env.VITE_FIREBASE_ROOT || import.meta.env.VITE_FIREBASE_PROJECT,
  },
  graphqlEndpoint: import.meta.env.VITE_GRAPHQL_ENDPOINT || '/graphql',
  mode: import.meta.env.MODE,
  prefix: 'grk',
  defaultTemplate: {
    theme: { prefix: 'grk', spacing: 3, pageMargin: 8 },
  },
  defaultStylesheet: {
    primary: '#0AD8B8',
    secondary: '#444444',
    styles: {
      heading1: {
        name: 'Heading 1',
        css: `font-size: 20px;
font-weight: bold;
border-bottom: 1.5px solid var(--primary);`,
      },
      heading2: {
        name: 'Heading 2',
        css: `font-size: 16px;
font-weight: bold;`,
      },
      disclaimer: {
        name: 'Disclaimer',
        css: `font-size: 12px;
font-style: italic;
color: #4b5563;`,
      },
    },
  },
  excelTemplatesFeature: true,
}
