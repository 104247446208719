<template>
  <div class="builder-footer flex h-fit flex-col justify-between !border-0 bg-white px-2">
    <div
      class="mt-2 flex h-fit w-full flex-row items-center justify-between border-t border-slate-300 bg-transparent pb-2 pt-1"
    >
      <div class="items start flex flex-col justify-between space-y-1">
        <div class="flex text-[7px] leading-[11.2px]" v-html="context.t.footer_disclaimer"></div>
        <div v-for="v in pageFootnotes" class="flex flex-row text-[7px]">
          <sup>{{ v.index }}</sup>
          <span class="leading-[11.2px]" v-html="v.value"></span>
        </div>
      </div>
      <!-- <img class="ml-2 mr-2 h-12 p-1" :src="footerImg" alt="Logo LFR" /> -->
      <div class="text-xs font-medium">{{ pageNumber }}/{{ pageCount }}</div>
    </div>
  </div>
</template>

<style>
.builder-footer {
  margin-top: auto !important;
  margin-bottom: calc(var(--page-margin) * -1);
}
</style>

<script setup>
// import { getCurrentInstance, onMounted, ref, computed } from 'vue'
import usePagination from '@100-m/hauru/src/applications/builder/composables/pagination'
import useTranslations from '@100-m/hauru/src/applications/builder/composables/translations'
const props = defineProps(['data', 'options', 'context'])
// const disclaimer = computed(() => {
//   const disc = data['nxpack-fund-ref'].disclaimer_footer || data.translation.disclaimer_footer
//     if (typeof disc !== 'string') return disc
//     return insane(marked(disc))
// })
const { pageNumber, pageCount } = usePagination(props)
const { pageFootnotes } = useTranslations(props)
// onMounted(() => {
//   initPagination()
// })
// const footerImg = computed(() => options.layout.theme?.assets?.find(el=>el.name==='logo-footer.png')?.src || '/logo-footer.png' )
</script>
