<template>
  <div class="flex w-full flex-row justify-between border-b-2 border-slate-400 pb-3" v-if="headerData.error">
    {{ headerData.error }}
  </div>
  <div
    class="-page-margin relative !mb-2 h-fit w-[calc(100%+var(--page-margin)*2)] px-6 pb-2 pt-3"
    style="margin: calc(-1 * var(--page-margin))"
    v-else-if="options.style === 'light'"
  >
    <div class="flex w-full flex-row justify-between border-b-2 border-slate-400 pb-3">
      <div class="flex max-w-[500px] flex-1 flex-col justify-between" :class="pageNumber === 1 ? 'h-[6.5rem]' : 'h-24'">
        <div class="flex h-8 flex-row items-center bg-slate-400 text-sm font-bold text-white" v-if="pageNumber === 1">
          <div class="bg-primary h-full w-10"></div>
          <div class="h-full w-2 bg-slate-200"></div>
          <div class="flex-1 px-2">{{ translate(headerData.category) }}</div>
          <div class="flex h-full flex-row items-center bg-slate-600 px-2">
            {{ headerData.date }}
          </div>
        </div>
        <div class="flex flex-1 flex-row items-center text-2xl capitalize">
          {{ headerData.fundName }}
        </div>
      </div>
      <div class="ml-16 flex" :class="pageNumber === 1 ? 'h-[6.5rem]' : 'h-24'">
        <img :src="logoImg" alt="logo" />
      </div>
    </div>
  </div>
  <div
    class="bg-secondary relative !mb-2 flex h-fit w-[calc(100%+var(--page-margin)*2)] flex-col items-center justify-between px-6 pb-6 pt-3 text-white"
    style="margin: calc(-1 * var(--page-margin))"
    v-else
  >
    <div class="flex h-fit w-full flex-row items-center justify-between bg-transparent">
      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-col font-bold">
          <div>
            {{ t['advertising_communication'] || 'advertising_communication' }}
          </div>
          <div class="text-lg capitalize">{{ headerData.fundName }}</div>
        </div>
      </div>
      <div class="flex flex-col items-end justify-between p-2 text-sm"></div>
      <div class="flex h-12 w-12">
        <img :src="logoImg" alt="logo" />
      </div>
    </div>
    <div class="absolute bottom-[-0.5rem] flex w-full flex-row justify-between font-bold">
      <div class="bg-primary flex min-w-[30%] px-6 py-1">
        {{ translate(headerData.category) }}
      </div>
      <div class="bg-primary flex px-6 py-1">
        {{ formatDate(headerData.endDate) }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import usePagination from '@100-m/hauru/src/applications/builder/composables/pagination'
import { getHeaderData, getLogoImg } from '@100-m/hauru/src/applications/builder/lib/header'
const props = defineProps(['data', 'options', 'context'])
const { pageCount, pageNumber } = usePagination(props)

function formatDate(date) {
  return new Intl.DateTimeFormat(props.context?.variables?.lang?.slice(0, 2), {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }).format(new Date(date))
}
function translate(key) {
  return props.context.translations[key] || key
}
const headerData = computed(() => {
  try {
    return getHeaderData(props.context)
  } catch (e) {
    return { error: e }
  }
})
const logoImg = computed(() => getLogoImg(props.context))
</script>
<script lang="ts">
import { BuilderOption } from '@100-m/hauru/src/applications/builder/composables/builderOptions'
const style: BuilderOption = {
  label: 'Header style',
  autocomplete: ({ data_component }) => ['normal', 'light'],
  default: ({ data_component }) => 'normal',
  attrs: {
    required: true,
  },
}
export default {
  viz: 'header',
  name: 'Greenrock header',
  api: {
    style,
  },
}
</script>
