<template>
  <div class="flex flex-1 flex-col p-2">
    <h2 class="font-bold" v-if="options.dataPoint">Data point: {{ options.dataPoint }}</h2>
    <div class="flex flex-col" v-if="options.request">
      <h2 class="font-bold">Underlying request</h2>
      <pre class="whitespace-pre-wrap rounded bg-slate-100 p-1">
<code v-html="options.request"></code>
      </pre>
    </div>
    <div class="p-1" v-if="options.text" v-html="options.text"></div>
  </div>
</template>

<script setup lang="ts">
const { data, options, context } = defineProps(['data', 'options', 'context'])
</script>
<script lang="ts">
export default {
  api: {
    dataPoint: {
      type: 'text',
      label: 'Data point',
      default: '',
    },
    text: {
      type: 'textarea',
      label: 'Text',
      default: '',
    },
    request: {
      type: 'textarea',
      label: 'Request',
      default: '',
    },
  },
}
</script>
