<template>
  <div class="builder-footer flex h-fit flex-col justify-between bg-white px-2 py-2">
    <div class="flex h-fit w-full flex-row items-start space-x-4 bg-transparent">
      <div class="relative flex w-2/5 flex-col justify-start space-y-1">
        <div class="border-primary absolute left-0 top-0 w-full border-t"></div>
        <div class="mt-1 flex flex-col text-[9px] leading-[11.2px]" v-html="context.t.footer_1 || footer_1"></div>
      </div>
      <div class="relative flex w-3/5 flex-col justify-start space-y-1">
        <div class="border-primary absolute left-0 top-0 w-full border-t"></div>
        <div class="mt-1 flex text-[9px] leading-[11.2px]">
          <div v-html="context.t.footer_2 || footer_2"></div>
          <div v-for="v in pageFootnotes" class="flex flex-row text-[7px]">
            <sup>{{ v.index }}</sup>
            <span class="leading-[11.2px]" v-html="v.value"></span>
          </div>
          <div class="ml-auto text-xs font-medium">{{ pageNumber }}/{{ pageCount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.builder-footer .border-t {
  margin-top: -1px; /* Adjust this value to position the border as needed */
}
.builder-footer {
  margin-top: auto !important;
  margin-bottom: calc(var(--page-margin) * -1);
}
</style>

<script setup>
// import { getCurrentInstance, onMounted, ref, computed } from 'vue'
import usePagination from '@100-m/hauru/src/applications/builder/composables/pagination'
import useTranslations from '@100-m/hauru/src/applications/builder/composables/translations'
const props = defineProps(['data', 'options', 'context'])
// const disclaimer = computed(() => {
//   const disc = data['nxpack-fund-ref'].disclaimer_footer || data.translation.disclaimer_footer
//     if (typeof disc !== 'string') return disc
//     return insane(marked(disc))
// })
const { pageNumber, pageCount } = usePagination(props)
const { pageFootnotes } = useTranslations(props)
// onMounted(() => {
//   initPagination()
// })
// const footerImg = computed(() => options.layout.theme?.assets?.find(el=>el.name==='logo-footer.png')?.src || '/logo-footer.png' )
</script>
