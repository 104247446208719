<script>
export const additions = {
  theme:
    '.pdf-page.grid\n  brick.no-title(data="block.header")\n  brick.expand\n  brick.no-title(data="block.footer")\n',
}
</script>

<template lang="pug">
.pdf-page
  brick(title="hello")
    div Page 1
</template>
