<template>
  <div
    class="-page-margin items-right relative !mb-2 flex h-fit w-[calc(100%+var(--page-margin)*2)] flex-col justify-between px-6 py-3"
    style="margin: calc(-1 * var(--page-margin))"
  >
    <div v-if="headerData.error">{{ headerData.error }}</div>
    <div class="flex h-fit w-full flex-row items-center justify-between bg-transparent">
      <div class="auto flex h-10">
        <img :src="logoImg" alt="logo" />
      </div>
      <div class="flex flex-col items-end justify-between p-2 text-sm"></div>
      <div class="flex flex-row items-center text-right">
        <div class="flex flex-col text-[10px]">
          <div class="font-bold">Advertising communication</div>
          <div>{{ headerData.fundName }}</div>
          <div>{{ headerData.date }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import usePagination from '@100-m/hauru/src/applications/builder/composables/pagination'
import { getHeaderData, getLogoImg } from '@100-m/hauru/src/applications/builder/lib/header'
const props = defineProps(['data', 'options', 'context'])
const { pageCount, pageNumber } = usePagination(props)

// onMounted(() => {
//   initPagination()
// })
function translate(key) {
  return props.context.translations[key] || key
}
const headerData = computed(() => {
  try {
    return getHeaderData(props.context)
  } catch (e) {
    return { error: e }
  }
})
const logoImg = computed(() => getLogoImg(props.context))
</script>
<script lang="ts">
import { BuilderOption } from '@100-m/hauru/src/applications/builder/composables/builderOptions'
const style: BuilderOption = {
  label: 'Header style',
  autocomplete: ({ data_component }) => ['normal', 'light'],
  default: ({ data_component }) => 'normal',
  attrs: {
    required: true,
  },
}
export default {
  viz: 'header',
  name: 'Alternative header',
  api: {
    style,
  },
}
</script>
