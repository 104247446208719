import * as XLSX from 'xlsx'

export function importConfigReport(
  event,
  cron2def,
  workflows,
  toast,
  commandrSDK,
  reports,
  specialtyNames,
  templatesNames,
  patternsNames,
) {
  const file = event.target.files[0]
  if (!file) {
    return
  }

  const reader = new FileReader()
  reader.onload = async e => {
    try {
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: 'array' })

      // Convert the first sheet to JSON
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]
      let jsonData = XLSX.utils.sheet_to_json(worksheet)

      const def2cron = {} // inverted cron2def
      for (const cron in cron2def) {
        def2cron[cron2def[cron]] = cron
      }

      const missingData = []

      if (!areReportsUnique(jsonData)) {
        toast({ description: $root.t.reports_not_unique, type: 'error', timeout: 5000 })
        return
      }

      jsonData = jsonData.map((r, index) => {
        const missingField = catchMissingUploadData(
          r,
          specialtyNames,
          templatesNames,
          patternsNames,
          workflows
            .v()
            .filter(w => w && !w.disabled)
            .map(w => w.name),
          Object.keys(def2cron),
        )
        r.schedule = capitalizeFirstLetter(r.schedule)

        if (missingField) {
          missingData.push({ id: `Row ${index + 2}`, missingField })
          return r
        } else {
          if (!def2cron[r.schedule]) {
            missingData.push({ id: `Row ${index + 2}`, missingField: 'Schedule' })
            return r
          }
          return formatJson(r, workflows, def2cron)
        }
      })

      jsonData.forEach(r => {
        if (!r.specialtyName) {
          delete r.specialtyName
        }
      })

      if (missingData.length > 0) {
        missingData.dlXLS('error.xlsx')
        toast({ description: $root.t.file_not_integrated, type: 'error', timeout: 5000 })
      } else {
        const disabledCurrentReports = disableReports(reports, commandrSDK)
        const merged = mergeReports(disabledCurrentReports, jsonData, commandrSDK)
        await commandrSDK.set('/data/presses', merged)
        toast({ description: $root.t.file_uploaded, type: 'success', timeout: 5000 })
      }
    } catch (err) {
      toast({ description: $root.t.failed_to_read_file + err, type: 'error', timeout: 5000 })
    }
  }
  reader.onerror = () => {
    toast({ description: $root.t.failed_to_read_file, type: 'error', timeout: 5000 })
  }
  reader.readAsArrayBuffer(file)
}

export function exportConfigReport(reports, cron2def, toast) {
  if (reports.length === 0) {
    toast({ description: $root.t.no_report, type: 'error', timeout: 5000 })
    return
  }
  const formatedReport = formatReport(reports, cron2def)
  formatedReport.dlXLS('reports.xlsx')
}

export function formatReport(reports, cron2def) {
  const report = reports.map(r => {
    return {
      id: r.id,
      fund_id: r.fundId,
      isin: r.isin || r.shareId,
      template: r.template,
      Pays_Report: r.language.toUpperCase().slice(-2),
      Langue_Report: r.language.toUpperCase().slice(0, 2),
      workflow_name: r.workflow,
      schedule: cron2def[r.schedule],
      patterns: r.patterns.join(', '),
      format: r.format,
      specialtyName: r.specialtyName,
    }
  })

  const emptySpecialty = report.every(r => !r.specialtyName)

  if (emptySpecialty) {
    report.forEach(r => {
      delete r.specialtyName
    })
  }

  return report
}

export function capitalizeFirstLetter(string) {
  return string
    .toLowerCase()
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-')
}

export function catchMissingUploadData(r, specialtyNames, templatesNames, patternsNames, workflowsNames, schedules) {
  const fieldsToCheck = [
    'fund_id',
    'template',
    'Pays_Report',
    'Langue_Report',
    'workflow_name',
    'schedule',
    'patterns',
    'format',
  ]

  if (r.specialtyName !== 'document by fund') {
    fieldsToCheck.push('isin')
  }

  const missingFields = new Set(fieldsToCheck.filter(field => r[field] == null))
  const patternsArray = r.patterns.split(', ').map(pattern => pattern.trim())

  const validations = [
    { value: r.template, list: templatesNames, field: 'template' },
    { value: patternsArray, list: patternsNames, field: 'patterns', isArray: true },
    { value: r.workflow_name, list: workflowsNames, field: 'workflow_name' },
    { value: r.schedule, list: schedules, field: 'schedule' },
    {
      value: r.specialtyName,
      list: specialtyNames,
      field: 'specialtyName',
      condition: r.specialtyName !== undefined,
      isSet: true,
    },
  ]

  validations.forEach(({ value, list, field, condition = true, isSet = false, isArray = false }) => {
    if (condition) {
      const isValid = isArray
        ? value.every(item => list.includes(item))
        : isSet
          ? list.has(value)
          : list.includes(value)

      if (!isValid) {
        missingFields.add(field)
      }
    }
  })
  return missingFields.size > 0 ? Array.from(missingFields).join(', ') : null
}

export function disableReports(reports) {
  if (Object.keys(reports).length === 0) return
  return reports.map(r => {
    return {
      ...r,
      disabled: true,
    }
  })
}

export function mergeReports(disabledReports, newReports) {
  if (!disabledReports) return newReports

  const reportsMap = new Map(disabledReports.map(report => [report.id, report]))

  newReports.forEach(report => {
    reportsMap.set(report.id, { ...reportsMap.get(report.id), ...report })
  })

  return Array.from(reportsMap.values())
}

function formatJson(r, workflows, def2cron) {
  return {
    id: r.id,
    fundId: r.fund_id,
    isin: r.isin, // for old client
    shareId: r.isin, // for specialty
    template: r.template,
    language: r.Langue_Report.toLowerCase() + '_' + r.Pays_Report.toLowerCase(),
    jurisdiction: r.Pays_Report.toLowerCase(),
    workflow: r.workflow_name,
    workflow_id: workflows.find(w => w.name === r.workflow_name)?.id,
    schedule: def2cron[r.schedule],
    patterns: r.patterns.split(', ').map(e => e.trim()),
    format: r.format,
    specialtyName: r.specialtyName,
    disabled: false,
  }
}

function areReportsUnique(reports) {
  const ids = reports.map(report => report.id)
  const uniqueIds = new Set(ids)
  return ids.length === uniqueIds.size
}
