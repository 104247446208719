<template>
  <span class="notification-badge">{{ props.count }}</span>
</template>

<style scoped>
.notification-badge {
  position: relative;
  cursor: default;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  color: white;
  background: red;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  line-height: 20px;
}
</style>

<script setup lang="ts">
const props = defineProps({
  count: { type: Number, required: true },
})
</script>
