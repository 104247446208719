<template>
  <table class="builder-table all-table leading-sm bottom-0 h-fit w-full rounded-lg" v-if="data" ref="element">
    <colgroup>
      <col
        :span="col.children ? col.children.length : 1"
        v-for="(col, idx) in columns"
        :class="'column-' + idx"
        class="table-col"
        :key="idx"
      />
    </colgroup>
    <thead>
      <tr class="table-header" v-for="(colArray, idx) in formattedColumns" :key="idx">
        <th
          class="table-header-cell overflow-hidden p-1 text-center"
          :key="col"
          v-for="(col, idx) in colArray"
          :rowspan="col.rowspan"
          :colspan="col.colspan"
          :class="col.class"
        >
          <div v-html="col.name"></div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="table-row" v-for="(row, idx) in tableRows" :key="idx" :class="row.class">
        <td
          class="table-cell overflow-hidden whitespace-nowrap p-1 text-center"
          v-for="(cell, cellIdx) in row.values"
          :key="cellIdx"
          :class="cell.class"
        >
          <div
            :style="
              row._level && cellIdx === 0 && indentLevels
                ? `margin-left: calc(var(--level-indent, 8px) * ${row._level})`
                : ''
            "
            v-html="cell.value"
          ></div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
const props = defineProps(['data', 'columns', 'start', 'end', 'indentLevels'])
function getColumnMaxDepth(columns) {
  let maxLevel = 0
  columns.forEach(col => {
    if (col.children) {
      const level = getColumnMaxDepth(col.children)
      if (level > maxLevel) {
        maxLevel = level
      }
    }
  })
  return maxLevel + 1
}
function getColNode(col, index) {
  if (index === 0) {
    return col
  }
  return col.children ? getColNode(col.children, index - 1) : null
}
const formattedColumns = computed(() => {
  const maxDepth = getColumnMaxDepth(props.columns)
  // const columns = Array.from({ length: maxDepth }, () => []);
  // let colIndex = 0;
  const columns = Array.from({ length: maxDepth }).map((_, idx) => {
    // debugger
    // if (idx === 1) {
    //   debugger
    // }
    return props.columns
      .map((col, colIdx) => {
        const node = getColNode(col, idx)
        const colClass = [`column-${colIdx}`]
        if (!node) return null
        if (node.field) colClass.push(`column-${node.field}`)
        if (typeof node === 'string') return colClass.push(`column-${node}`)
        if (Array.isArray(node)) {
          return node.map((n, childIndex) => ({
            name: n.name || n.field || n,
            rowspan: 0,
            class: [...colClass, `${colClass}-${childIndex}`, `column-${n.field || n}`],
          }))
        }
        if (!node.children) {
          return { name: node.name || node.field || node, rowspan: 0, class: colClass }
        } else {
          return { name: node.name || node.field || node, colspan: node.children.length, class: colClass }
        }
      })
      .filter(Boolean)
      .flat()
  })
  return columns
})
const tableRows = computed(() => {
  return props.data
    .map((row, rowIdx) => {
      const values = props.columns
        .map((col, colIdx) => {
          if (!col.children)
            return {
              class: [`column-${colIdx}`, `column-${col.field || col}`],
              value: row?.[col.field || col],
            }
          return col.children.map((child, childIndex) => {
            return {
              value: row?.[col.field || col]?.[child.field || child],
              class: `column-${colIdx} column-${colIdx}-${childIndex} column-${child.field || child}`,
            }
          })
        })
        .flat()
      return {
        _level: row._level,
        _striped: row._striped,
        class: [`level-${row._level}`, `${row._striped ? 'striped' : ''}`],
        values,
      }
    })
    .filter((row, idx) => {
      if (props.start && idx < props.start) return false
      if (props.end && idx >= props.end) return false
      return true
    })
})
const element = ref()
defineExpose({
  element,
})
</script>
