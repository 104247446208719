<template>
  <builder-bar v-bind="props" class="grk-bar">
    <template #bar="{ size, offset, value, options, categoryIndex, formattedValue }">
      <div class="flex h-full max-h-12 w-full flex-col justify-center">
        <div
          class="relative h-full min-h-[1rem]"
          :style="{
            width: size(value) + '%',
            backgroundColor: options.palette[categoryIndex],
            left: offset(value),
          }"
        >
          <div
            class="bar-value label absolute top-1/2 -translate-y-1/2 transform"
            :class="[labelColorClass(size(value))]"
            :style="{ ...labelOffset(size(value), value) }"
          >
            {{ formattedValue }}
          </div>
        </div>
      </div>
    </template>
    <template #bar-value><div></div></template>
  </builder-bar>
</template>

<script setup lang="ts">
// import { bar as api } from '@100-m/hauru/src/applications/builder/composables/builderOptions'
import { BuilderProps } from '@100-m/hauru/src/applications/builder/composables/builderComponent'
const props = withDefaults(defineProps<BuilderProps>(), {})
const cutoff = 18
function labelColorClass(sizeValue: number) {
  return sizeValue > cutoff ? 'text-white' : 'text-black'
}
function labelOffset(sizeValue: number, value: number) {
  if (value < 0) {
    if (sizeValue > cutoff) {
      return { left: '0.25rem' }
    }
    return { right: 'calc(100% + 0.25rem)' }
  }
  if (sizeValue > cutoff) {
    return { right: '0.25rem' }
  }
  return { left: 'calc(100% + 0.25rem)' }
}
</script>

<script lang="ts">
import builderBar from '@100-m/hauru/src/applications/builder/components/builder-bar.vue'

export default {
  ...builderBar,
}
</script>

<style scoped>
.grk-bar :deep(.bar-group) {
  flex: 1;
  /* max-height: 3rem; */
}
</style>
