import { PlatformBuilder } from '@100-m/hauru/src/utils/platformBuilder'
import BaseApp from '@100-m/hauru/src/App.vue'
import BaseModule from '@100-m/hauru/src/module'
import Switch from '@100-m/hauru/src/applications/switch/module'
import AssetManagement from '@100-m/hauru/src/applications/asset-management/module'
import ClientEdition from '@100-m/hauru/src/applications/client-edition/module'
import Builder from '@100-m/hauru/src/applications/builder/module'
import Design from '@100-m/hauru/src/applications/design/module'
import auth0Login from '@100-m/hauru/src/features/auth/auth0-login.js'
import keycloakAuth from '@100-m/hauru/src/features/auth/keycloak-auth.js'
import config, { extendConfig } from '@100-m/hauru/src/config.js'

import ExtendAssetManagement from './applications/asset-management/module'
import ExtendClientEdition from './applications/client-edition/module.js'
import ExtendBuilder from './applications/builder/module'
import clientConfig from './config'
import policy from '@100-m/hauru/src/applications/client-edition/utils/policy'

import styleTailwind from '@100-m/hauru/src/applications/builder/index.css?inline'
import style1 from '@100-m/hauru/src/assets/css/platform.css?inline'
import style2 from '@100-m/hauru/src/applications/asset-management/index.css?inline'
import style3 from '@100-m/hauru/src/applications/client-edition/index.css?inline'
import style4 from './index.css?inline'
import builderStyle from './applications/builder/index.css?inline'

const stylePlatform = [style1, style2, style3, style4].join('\n\n')
const styleMixin = {
  computed: {
    style() {
      // TODO: add VITE_RAWJS_OBJECT_NOEXTEND for every app
      if (['builder', 'design'].includes($root.app)) {
        Object.keys(Object).map(k => delete Object.prototype[k])
      } else {
        Object.extend()
      }
      if (['builder', 'design'].includes($root.app)) return [styleTailwind, builderStyle].join('\n\n')
      return stylePlatform
    },
  },
}
BaseApp.mixins = (BaseApp.mixins || []).concat(styleMixin)

extendConfig(clientConfig)

let platform
// if in IDS config
if (import.meta.env.VITE_KEYCLOAK_REALM && import.meta.env.VITE_COMMANDR_FILESYSTEM_MODE === 'docker') {
  const authConfig = keycloakAuth(config.keycloakAuth, config.graphqlEndpoint)
  platform = new PlatformBuilder()
    .initFirebase(config.firebase)
    .setBaseApp(BaseApp)
    .addApp(BaseModule)
    .addApp(Switch)
    .addApp(AssetManagement, ExtendAssetManagement)
    .addApp(ClientEdition, ExtendClientEdition)
    .addApp(Builder, ExtendBuilder)
    .addApp(Design)
    .setAuth(authConfig)
    .setRouterMode('hash') // fix internal problem routing with nginx
    .setPolicy(policy)
} else {
  // standard config
  const auth0 = auth0Login(config.auth)
  platform = new PlatformBuilder()
    .initFirebase(config.firebase)
    .setBaseApp(BaseApp)
    .addApp(BaseModule)
    .addApp(Switch)
    .addApp(AssetManagement, ExtendAssetManagement)
    .addApp(ClientEdition, ExtendClientEdition)
    .addApp(Builder, ExtendBuilder)
    .addApp(Design)
    .setAuth(auth0)
    .setPolicy(policy)
}

const app = platform.build()
app.mount('main')

window.app = app
window.platform = platform
