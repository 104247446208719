<template>
  <builder-block>
    <div class="profile">
      <div class="managers">
        <img src="/public/manager1.png" alt="manager1 image" />
        <div class="firstname">Yann</div>
        <div class="name">Bloch</div>
      </div>
      <div class="managers">
        <img src="/public/manager2.png" alt="manager image" />
        <div class="firstname">Julien</div>
        <div class="name">Dumont</div>
      </div>
    </div>
  </builder-block>
</template>

<script setup></script>

<style scoped>
.profile {
  display: flex;
}

.profile .managers {
  font-size: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
}

.profile .name {
  font-weight: 600;
  text-transform: uppercase;
}

.profile .firstname {
  padding-top: 4px;
}

.profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
</style>
