import jwtDecode from 'jwt-decode'
import axios from 'axios'

const rolesWeights = {
  admin: 10,
  consulting: 9,
  supervisor: 8,
  'admin-reporting': 7,
  'reporting-officer': 6,
  'reporting-contributor': 5,
  user: 4,
  reader: 3,
  auditor: 2,
}

/**
 * Get the most weighted role from the given roles list
 * @param roles {Array<string>} List of roles
 * @returns {string} Most weighted role or 'anonymous'
 */
function mostWeightedRole(roles) {
  return (
    roles.filter(r => Object.keys(rolesWeights).includes(r)).sort((a, b) => rolesWeights[b] - rolesWeights[a])[0] ||
    'anonymous'
  )
}

function _parseIdToken(token, persist = true) {
  const decodedToken = jwtDecode(token)
  if (persist) localStorage.idToken = token
  // TODO needs object.__.filter to work
  const custom = decodedToken.__.filter((v, k) => /https:/.test(k)).__.v()
  const generic = decodedToken.__.filter((v, k) => !/https:/.test(k))
  const profile = Object.assign(generic, ...custom)
  profile.idToken = token
  profile.role = mostWeightedRole(profile.realm_access.roles)
  return profile
}

async function getRealm(graphqlEndpoint) {
  if (!graphqlEndpoint) return null
  const envFromApi = await axios({
    url: `${graphqlEndpoint}/env`,
    method: 'get',
  })
  return envFromApi.data?.realm
}

/**
 * Keycloak auth module
 * @param url {string} Url of the keycloak service
 * @param realm {string} Realm
 * @param clientId {string} Client id
 * @returns {{logout(): Promise<void>, onRequest(*): *, login(*, *): Promise<unknown>, initAuth(): Promise<any|null>}|*|null|Promise<unknown>}
 */
export default function ({ url, realm, clientId } /* = config.keycloakAuth */, graphqlEndpoint) {
  let idToken
  let refreshToken
  let finalRealm = realm

  return {
    async login(email, password) {
      finalRealm = (await getRealm(graphqlEndpoint)) || realm // Get realm from the API in case it is overriden
      const tokenUrl = `${url}/realms/${finalRealm}/protocol/openid-connect/token`
      const result = await (
        await fetch(tokenUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            username: email,
            password,
            grant_type: 'password',
            client_id: clientId,
          }),
        })
      ).json()

      idToken = result.access_token
      refreshToken = result.refresh_token
      return _parseIdToken(result.access_token)
    },

    async logout() {
      finalRealm = (await getRealm(graphqlEndpoint)) || realm // Get realm from the API in case it is overriden
      const logoutUrl = `${url}/realms/${finalRealm}/protocol/openid-connect/logout`
      await fetch(logoutUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          client_id: clientId,
          refresh_token: refreshToken,
        }),
      })
      const revokeTokenUrl = `${url}/realms/${finalRealm}/protocol/openid-connect/revoke`
      await fetch(revokeTokenUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          client_id: clientId,
          token: refreshToken,
          token_type_hint: 'refresh_token',
        }),
      })
      await fetch(revokeTokenUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          client_id: clientId,
          token: idToken,
          token_type_hint: 'access_token',
        }),
      })
      idToken = null
      refreshToken = null
      localStorage.removeItem('idToken')
      localStorage.clear()
      sessionStorage.clear()
    },

    onRequest(config) {
      // console.log('on request', config)
      config.headers.Authorization = `Bearer ${idToken}`
      config.headers['x-valuation-style'] = $root.valuationStyle
      return config
    },

    async initAuth() {
      // Login from query string token
      const params = new URLSearchParams(window.location.search)
      if (params.has('token')) {
        idToken = params.get('token')
        return _parseIdToken(idToken, false)
      }

      // Login from localStorage
      if (localStorage.idToken) {
        idToken = localStorage.idToken
        return _parseIdToken(idToken)
      }

      return null
    },
  }
}
