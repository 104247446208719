<template>
  <h2 class="bg-slate-600 p-1 text-lg font-bold leading-tight text-white">
    {{ options.title && context.t[options.title.replace(/^translation\./, '')] }}
  </h2>
</template>
<script setup lang="ts">
const { data, options, context } = defineProps(['data', 'options', 'context'])
</script>
<script lang="ts">
export default {}
</script>
